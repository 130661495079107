<template>
    <div class="position-relative" :class="{selected: isSelected}" v-ripple @click="edit">
        <!-- <v-btn x-small v-if="component.type !== 'spacer' && !hideHandles" color="primary" icon class="edit-btn ma-0"
            @click="edit">
            <v-icon>mdi-pencil</v-icon>
        </v-btn> -->
        <v-btn v-if="!hideHandles" x-small color="error" icon class="delete-btn ma-0" @click="remove">
            <v-icon>mdi-trash-can</v-icon>
        </v-btn>
        <v-chip v-if="!hideHandles" x-small class="component-type-tag">{{ component.name }}</v-chip>

        <v-sheet v-if="component.type === 'text'" class="component-wrapper pa-2" :class="hideHandles?'':'border-dashed'" min-height="50"
            elevation="0" rounded width="100%">
            <div v-html="textContent"></div>
        </v-sheet>
        <v-sheet v-else-if="component.type === 'banner'" class="component-wrapper pa-1" :class="hideHandles?'':'border-dashed'"
            elevation="0" rounded>
            <Banner :component="component" />
        </v-sheet>
        <v-sheet v-else-if="component.type === 'item' && component.extended"
            class="component-wrapper pa-1" :class="hideHandles?'':'border-dashed'" elevation="0" rounded>
            <ExtendedItemComponent :item="component" />


        </v-sheet>
        <v-sheet v-else-if="component.type === 'group'" class="component-wrapper pa-1" :class="hideHandles?'':'border-dashed'"
            elevation="0" rounded>
            <FeaturedGroup :group="component"/>


        </v-sheet>
        <v-sheet v-else-if="component.type === 'vgroup'" class="component-wrapper pa-1" :class="hideHandles?'':'border-dashed'"
            elevation="0" rounded>
            <VirtualGroup :group="component" />


        </v-sheet>
        <v-sheet v-else-if="component.type === 'item' && !component.extended"
            class="component-wrapper pa-1" :class="hideHandles?'':'border-dashed'" elevation="0" rounded>
            <ItemComponent :item="component" />


        </v-sheet>
        <v-sheet v-else-if="component.type === 'spacer'" class="component-wrapper pa-1" :class="hideHandles?'':'border-dashed'"
            elevation="0" rounded width="100%" :style="{ height: `${component.height}px` }">
            <div v-if="!hideHandles" class="resize-handle text-center" @mousedown.stop="startResize($event, component)">
            </div>
        </v-sheet>
        <v-sheet v-else class="component-wrapper pa-1" :class="hideHandles?'':'border-dashed'" elevation="0" rounded
            width="100%" height="300">
        </v-sheet>
    </div>
</template>

<script>
import ExtendedItemComponent from "./ExtendedItemComponent.vue";
import ItemComponent from "./ItemComponent.vue";
import FeaturedGroup from "./FeaturedGroup.vue";
import VirtualGroup from "./VirtualGroup.vue";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import Banner from "./Banner.vue";
import { mapGetters } from "vuex"

export default {
    name: "CustomComponent",
    components: { ExtendedItemComponent, ItemComponent, FeaturedGroup, VirtualGroup, Banner, FontAwesomeIcon },
    props: {
        component: {
            type: Object,
            required: true,
        },
        hideHandles: {
            type: Boolean,
            required: false,
        },
        isSelected: {
            type: Boolean,
            required: false,
        }
    },
    data() {
        return {
            resizing: false,
            startY: 0,
            startHeight: 0,
            activeComponent: null,
            minSpacerHeight: 20
        }
    },
    computed: {
        ...mapGetters({
            lang: "app/currentLanguage"
        }),
        textContent() {
            if (!this.component || !this.component.content)
                return this.$t("generic.lang_editText")


            return this.component.content[this.lang];
        },
    },
    methods: {
        remove() {
            this.$emit("remove")
        },
        edit() {
            this.$emit("edit")
        },
        startResize(e, component) {
            if (e.target.classList?.value.includes('resize-handle')) {
                e.preventDefault();
                e.stopPropagation();
            }
            this.resizing = true;
            this.startY = e.clientY;
            this.startHeight = component.height;
            this.activeComponent = component;
            document.addEventListener('mousemove', this.resize);
            document.addEventListener('mouseup', this.stopResize);
        },
        resize(e) {
            if (!this.resizing) return;
            const dy = e.clientY - this.startY;
            this.activeComponent.height = Math.max(this.startHeight + dy, this.minSpacerHeight); // Minimum height of 20px
        },
        stopResize() {
            this.resizing = false;
            document.removeEventListener('mousemove', this.resize);
            document.removeEventListener('mouseup', this.stopResize);
        }
    },
    beforeDestroy() {
        // Cleanup event listeners
        document.removeEventListener('mousemove', this.resize);
        document.removeEventListener('mouseup', this.stopResize);
    }

}

</script>

<style>
.edit-btn {
    position: absolute;
    right: 5px;
    top: 5px;
    z-index: 1;
}

.delete-btn {
    position: absolute;
    right: 5px;
    bottom: 2px;
    z-index: 1;
}

.border-dashed {
    border: 1px dashed lightblue;
}

.component-type-tag {
    position: absolute;
    left: 5px;
    bottom: 2px;
    z-index: 1;
}

.border-dashed {
    border: 1px dashed lightgray !important;
}

.resize-handle {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 10px;
    background-color: rgba(0, 0, 0, 0.1);
    cursor: ns-resize;
    z-index: 3;
}

.resize-handle:hover {
    background-color: rgba(0, 0, 0, 0.2);
}

.component-wrapper {
    overflow: hidden;
}
.selected .border-dashed{
    border: 2px dashed #007bff !important;
    box-shadow: 0px 0px 14px 5px #007bff55 inset !important;
}
</style>