<template>
    <v-card :loading="loading">
        <v-card-title> {{ $t('kiosk.lang_frontPageTemplates') }}<v-spacer/><v-btn color="success" depressed @click="showDialog=true">{{ $t("generic.lang_add") }}</v-btn></v-card-title>
        <v-divider class="ma-0" />
        <v-card-text class="pa-0">
            <v-data-table :items="templates">

            </v-data-table>
        </v-card-text>
        <CreateUpdateFrontPageTemplate v-if="showDialog" @closeDialog="showDialog=false" />
    </v-card>
</template>

<script lang="js">
import { ENDPOINTS } from '@/config';
import { Events } from "@/plugins/events";
import CreateUpdateFrontPageTemplate from './CreateUpdateFrontPageTemplate.vue';

export default {
    name: 'KioskFrontPageTemplates',
    components: {CreateUpdateFrontPageTemplate},
    data(){
        return {
            loading: false,
            showDialog: false,
            templates:[],
        }
    },
    computed:{

    },
    methods: {
        getTemplates(){
            this.loading = true;
            this.axios.post(ENDPOINTS.KIOSK.SETTINGS.DESIGN.FRONTPAGE.TEMPLATE.GETALL)
                .then(res => {
                    if (res.status === 200) {

                    } else {
                        Events.$emit("showSnackbar", {
                            message: this.$t('generic.lang_errorOccurred'),
                            color: "error"
                        });
                    }
                }).catch(err => {
                    Events.$emit("showSnackbar", {
                        message: this.$t('generic.lang_errorOccurred') + ' ' + err.message,
                        color: "error"
                    });
                }).finally(() => {
                    this.loading = false;
                })
        }
    },
    mounted(){
        this.getTemplates();
    }
}

</script>